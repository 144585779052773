<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 user">
            <div class="col-12">
                <div class="card">
                    <!-- Card header -->
                    <div class="card-header border-bottom">
                        <div class="user d-flex align-items-center">
                            <div class="col-6">
                                <h5 class="mb-0 ms-0">Invoices</h5>
                            </div>
                            <div class="col-6 text-end">
                                <material-button class="float-right btn btm-sm" @click="this.$router.push('/invoices/create');">
                                    <i class="fas fa-user-plus me-2"></i>
                                    Add Invoice
                                </material-button>
                            </div>
                        </div>
                    </div>

                    <!-- Card body -->
                    <div class="px-0 pb-0 card-body">
                        <!-- Table -->
                        <table class="table table-flush mt-3 table-responsive">
                            <thead class="thead-light">
                                <tr>
                                    <th>Invoice No</th>
                                    <th>Customer</th>
                                    <th>Status</th>
                                    <th>Issued Date</th>
                                    <th>Due Date</th>
                                    <th>Total</th>
                                    <th>Currency</th>
<!--                                    <th>Created At</th>-->
                                    <th class="text-end">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                              <tr v-for="invoice in invoices" :key="invoice.id">
                                <td class="text-sm font-weight-normal">{{invoice.invoice_name}}</td>
                                <td class="text-sm font-weight-normal">{{invoice?.customer?.name}}</td>
                                <td class="text-sm font-weight-normal">
                                  <material-badge variant="gradient" :color="invoice.status === 'pending' ? 'secondary' : 'success'" > {{invoice.status}}</material-badge>
                                </td>
                                <td class="text-sm sm font-weight-normal">{{invoice.formatted_issue_date}}</td>
                                <td class="text-sm font-weight-normal">{{invoice.formatted_due_date}}</td>
                                <td class="text-sm font-weight-normal">{{invoice.total}}</td>
                                <td class="text-sm font-weight-normal">{{invoice.currency}}</td>
<!--                                <td class="text-sm font-weight-normal">{{invoice.created_at}}</td>-->
                                <td class="text-sm font-weight-normal">
                                    <div class="text-end">
                                         <material-button color="info" @click="openLinkInNewTab(invoice.path);" class="m-1 btn-circle" title="Preview" size="sm">
                                            <i class="fas fa-eye"></i>
                                        </material-button>
                                         <material-button v-if="invoice.status === 'pending'" @click="sendToCustomer(invoice.id)" class="m-1 btn-circle" title="Send Invoice to Client" size="sm">
                                            <i class="fas fa-play"></i>
                                        </material-button>
                                        <material-button color="warning" @click="this.$router.push(`/invoices/update/${invoice.id}`)" class="m-1 btn-circle" title="Edit" size="sm">
                                            <i class="fas fa-pen"></i>
                                        </material-button>
                                        <material-button v-if="invoice.status === 'pending'" color="success" @click="markAsPaid(invoice.id)" class="m-1 btn-circle" title="Mark Invoice as Paid" size="sm">
                                            <i class="fas fa-check"></i>
                                        </material-button>
<!--                                        <material-button id="delBut" @click="showProMessage()" class="m-1 btn-circle" size="sm" color="danger"-->
<!--                                            title="delete">-->
<!--                                            <i class="fas fa-trash"></i>-->
<!--                                        </material-button>-->
                                    </div>
                                </td>
                              </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import showSwal from "@/mixins/showSwal";
import authHeader from "@/services/auth-header.js";
import axios from "axios";
import MaterialBadge from "@/components/MaterialBadge.vue";

export default {
    name: "Invoice",
    components: {
      MaterialBadge,
      MaterialButton,
    },
    data() {
        return {
            invoices: []
        }
    },
    async mounted() {
      await this.fetchInvoices()
    },
    methods: {
      async fetchInvoices() {
        const url = process.env.VUE_APP_API_BASE_URL + '/invoice'
        const { data } = await axios.get(url, {
             headers: authHeader()
        })
        this.invoices = data.data
      },
      openLinkInNewTab (url) {
        window.open(url, '_blank');
      },
      async markAsPaid(id) {
        const response = await showSwal.methods.showSwalConfirmationDelete('Are you sure you want to mark invoice as Paid')

        if (response.isConfirmed) {
          try {
             const url = process.env.VUE_APP_API_BASE_URL + `/mark/invoice/${id}/paid`
              const { data } = await axios.post(url, {
                 headers: authHeader()
              })

            await this.fetchInvoices()
             this.showMessage('success', data.message)
          } catch (e) {
            this.showMessage('error', 'Failed to send invoice')
          }
        }
      },
      async sendToCustomer(id) {
        const response = await showSwal.methods.showSwalConfirmationDelete('Are you sure you want to send this invoice to the customer?')

        if (response.isConfirmed) {
          try {
             const url = process.env.VUE_APP_API_BASE_URL + `/send/invoice/${id}`
              const { data } = await axios.post(url, {
                 headers: authHeader()
              })

            await this.fetchInvoices()
             this.showMessage('success', data.message)
          } catch (e) {
            this.showMessage('error', 'Failed to send invoice')
          }
        }
      },
      showMessage(type, message) {
          showSwal.methods.showSwal({
              type,
              message,
              width: 500
          });
      }
    }
};
</script>

<style scoped>
table thead tr th {
    padding-left: 1.5 rem !important;
}
</style>
