<script setup>
import { Form as VeeForm } from 'vee-validate'
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import { ref, computed, onMounted, watch } from "vue";
import axios from "axios";
import { useRouter } from 'vue-router';
import authHeader from "@/services/auth-header.js";
import showSwal from "@/mixins/showSwal.js";

const props = defineProps({
  invoiceId: {
    type: Number,
    default: null,
  }
});

const router = useRouter();
const API_URL = process.env.VUE_APP_API_BASE_URL;

const form = ref({
  customer_id: null,
  due_date: null,
  issue_date: null,
  currency: null,
  invoice_name: null,
  additional_note: null,
  line_items: [],
  total: null
});

const numOfRows = ref(0);
const customers = ref([]);

const currencies = [
  { id: 1, name: "GHS" },
  { id: 2, name: "USD" },
  { id: 3, name: "GBP" },
  { id: 4, name: "NGN" },
];

// Add new row for line items
const addRow = () => {
  form.value.line_items.push({
    id: '#',
    item: null,
    unit_price: null,
    quantity: null,
    subtotal: null
  });
  numOfRows.value += 1;
};

const deleteRow = async (index) => {
  const row = form.value.line_items[index];

  if (row.id !== '#') {
    try {
      const { data } = await axios.delete(`${API_URL}/invoice/item/${row.id}`, {
        headers: authHeader(),
      });

      if (data.message === 'Invoice Item Deleted successfully') {
        form.value.line_items.splice(index, 1);
        numOfRows.value = form.value.line_items.length;
      }
    } catch (error) {
      console.error('Error deleting invoice item:', error);
       showMessage('error', 'Failed to delete row')
    }
  } else {
    form.value.line_items.splice(index, 1);
    numOfRows.value = form.value.line_items.length;
  }
};


const total = computed(() => {
  return form.value.line_items.reduce((acc, item) => acc + (item.quantity * item.unit_price || 0), 0);
});

const fetchCustomers = async () => {
  const url = `${API_URL}/customer`;
  const { data } = await axios.get(url, { headers: authHeader() });
  customers.value = data.data;
};

const fetchInvoice = async () => {
  if (props.invoiceId) {
    const { data } = await axios.get(`${API_URL}/invoice/${props.invoiceId}`, {
      headers: authHeader()
    });

    console.log('data here', data.data)
    form.value = {
      ...data.data,
      customer_id: data.data?.customer?.id,
      line_items: data.data.invoice_items || []
    };
    numOfRows.value = form.value.line_items.length;
  }
  console.log(form.value ,'here')
};

const showMessage = (type, message) => {
    showSwal.methods.showSwal({
        type,
        message,
        width: 500
    });
}

const submit = async () => {
  try {
    form.value.total = form.value.line_items.reduce((acc, item) => acc + (item.quantity * item.unit_price || 0), 0);

    let response;
    if (props.invoiceId) {
      response = await axios.put(`${API_URL}/invoice/${props.invoiceId}`, form.value, { headers: authHeader() });
    } else {
      response = await axios.post(`${API_URL}/invoice`, form.value, { headers: authHeader() });
    }

    console.log('Response:', response);
    showMessage('success', response.data.message)
    await router.push('/invoices');
  } catch (error) {
    console.log(error)
    const errors = error.response.data.errors
    if (errors.length > 0) {
      errors.forEach(err => showMessage('error', err[0]))
    } else {
       showMessage('error', error.response.data.message)
    }
  }
};

onMounted(() => {
  fetchCustomers();
  if (props.invoiceId) {
    fetchInvoice();
  } else {
    addRow(); // Start with one row for new invoices
  }
});
</script>


<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3">
              <h6 class="text-white text-capitalize ps-3">{{ props.invoiceId ? 'Edit Invoice' : 'New Invoice' }}</h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <div class="table-responsive p-0">
              <VeeForm role="form" class="text-start mt-3" @submit="submit">
                <div class="container">
                   <!-- Customer and Invoice details form -->
                   <div class="row">
                      <div class="col-md-3">
                         <material-input id="name" label="Name" placeholder="Appointment Invoice" variant="static" v-model:value="form.invoice_name" name="name" :is-required=true />
                      </div>
                       <div class="col-md-2">
                        <label class="ms-0">Select Customer </label>
                        <select v-model="form.customer_id" class="form form-control" name="customer_id" required>
                          <option selected>Select Customer</option>
                          <option v-for="customer in customers" :key="customer.id" :value="customer.id">{{customer.name}}</option>
                        </select>
                       </div>

                        <div class="col-md-2">
                         <material-input id="issued_date" label="Issue Date" variant="static" v-model:value="form.issue_date" name="issue_date" type="date" :is-required=true />
                      </div>

                      <div class="col-md-2">
                         <material-input id="due_date" label="Due Date" variant="static" v-model:value="form.due_date" name="due_date" type="date" :is-required=true />
                      </div>
                       <div class="col-md-3">
                        <label class="ms-0">Select Currency</label>
                        <select v-model="form.currency" class="form form-control" required>
                          <option selected value="">Select Currency</option>
                          <option v-for="currency in currencies" :key="currency.id" :value="currency.name">{{currency.name}}</option>
                        </select>
                       </div>
                   </div>

                  <!-- Line items table -->
                  <div class="mt-5">
                    <material-button @click.prevent="addRow" class="m-1 btn-circle" size="sm" color="danger" title="Add Row">
                      <i class="fas fa-plus"></i> Add Row
                  </material-button>
                    <table class="table table-flush mt-3">
                        <thead class="thead-light">
                            <tr>
                                <th>Item</th>
                                <th>Quantity</th>
                                <th>Unit Price</th>
                                <th>Subtotal</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(line_item, index) in form.line_items" :key="index">
                            <td>
                                <material-input :id="'item_' + index" v-model:value="line_item.item" placeholder="Leader Stripe Bear"/>
                            </td>
                            <td>
                                <material-input :id="'quantity_' + index" v-model:value="line_item.quantity" placeholder="2" type="number"/>
                            </td>
                            <td>
                                <material-input :id="'unit_price_' + index" v-model:value="line_item.unit_price" placeholder="2000" type="number"/>
                            </td>
                            <td>
                                <material-input :id="'subtotal_' + index" :value="line_item.subtotal=line_item.quantity * line_item.unit_price" :disabled="true" />
                            </td>
                            <td>
                                  <material-button @click.prevent="deleteRow(index)" title="Delete Row" class="m-1 btn-circle" size="sm" color="danger">
                                      <i class="fas fa-trash"></i>
                                  </material-button>
                            </td>
                          </tr>
                        </tbody>
                    </table>
                  </div>

                  <!-- Additional notes and total -->
                  <div class="mt-5 mb-5">
                    <div class="row">
                       <div class="col-md-4">
                         <material-input id="notes" label="Additional Note" variant="static" placeholder="Enter any additional notes here..." v-model:value="form.additional_note" name="additional_notes"  />
                       </div>

                       <div class="col-md-4">
                         <material-input id="total" label="Total" variant="static" :disabled=true placeholder="5000" v-model:value="total"/>
                       </div>

                       <div class="col-md-3">
                          <material-button type="submit" class="m-1 btn-circle float-right" size="sm" color="success" title="Submit">
                              <i class="fas fa-mail"></i> Submit
                          </material-button>
                       </div>
                  </div>

                  </div>
                </div>
              </VeeForm>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
